import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "card-body py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_download_excel = _resolveComponent("download-excel")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tabla.serviciosProgramadosListado.titulo")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("tabla.serviciosProgramadosListado.numeroServicios", {
              numero: _ctx.serviciosListado?.length ?? 0,
            })), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_input, {
          class: "ms-5",
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          style: {"width":"300px"},
          placeholder: _ctx.$t('tabla.buscador.placeholder')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_download_excel, {
          data: _ctx.serviciosListado,
          labels: _ctx.csvLabels,
          fields: _ctx.csvFields,
          name: _ctx.csvName
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_7, [
              _createElementVNode("i", {
                class: "fas fa-file-excel fs-3",
                title: _ctx.$t('tabla.serviciosProgramadosListado.imprimir.boton')
              }, null, 8, _hoisted_8)
            ])
          ]),
          _: 1
        }, 8, ["data", "labels", "fields", "name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_el_table, {
        data: _ctx.result,
        style: {"width":"100%"},
        stripe: "",
        "default-sort": { prop: 'createdOn', order: 'descending' },
        onSortChange: _ctx.sortChange
      }, {
        default: _withCtx(() => [
          (_ctx.isAdmin)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                label: _ctx.$t('tabla.familias.cabecera.comarca'),
                prop: "nombreComarca",
                sortable: "",
                "min-width": "150"
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.lugar'),
            sortable: "",
            prop: "lugarCalculado",
            "min-width": "150"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.nombre'),
            prop: "nombre",
            sortable: "",
            "min-width": "150"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.descripcion'),
            prop: "descripcion",
            sortable: "",
            "min-width": "150"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.descripcion.length > 100
                ? scope.row.descripcion.slice(0, 100) + "..."
                : scope.row.descripcion), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.peticiones'),
            prop: "solicitudes",
            sortable: "",
            width: "130"
          }, {
            default: _withCtx(({ row }) => [
              _createElementVNode("div", null, _toDisplayString(row.solicitudes), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.aceptados'),
            prop: "admisiones",
            sortable: "",
            width: "130"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.inicio'),
            prop: "servicio.inicioPrevisto",
            formatter: _ctx.toLocaleDateString,
            width: "110"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProgramadosListado.cabecera.fin'),
            prop: "servicio.finPrevisto",
            formatter: _ctx.toLocaleDateString,
            width: "110"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            width: "50",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                type: "text",
                onClick: ($event: any) => (_ctx.handleEdit(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosProgramadosListado.fila.ver")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSortChange"]),
      _createVNode(_component_el_pagination, {
        class: "my-5 float-end",
        background: "",
        "page-size": 100,
        layout: "prev, pager, next",
        total: _ctx.serviciosListado.length,
        "hide-on-single-page": true,
        "current-page": _ctx.currentPage,
        onCurrentChange: _ctx.handleCurrentPageChange
      }, null, 8, ["total", "current-page", "onCurrentChange"])
    ])
  ]))
}