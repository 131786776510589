import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "card-body py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_download_excel = _resolveComponent("download-excel")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tabla.serviciosPrestadosListado.titulo")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("tabla.serviciosFamiliasListado.numeroServicios", {
              numero: _ctx.serviciosListado?.length ?? 0,
            })), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_input, {
          class: "ms-5",
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          style: {"width":"300px"},
          placeholder: _ctx.$t('tabla.buscador.placeholder')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_download_excel, {
          data: _ctx.serviciosListado,
          labels: _ctx.csvLabels,
          fields: _ctx.csvFields,
          name: _ctx.csvName
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_7, [
              _createElementVNode("i", {
                class: "fas fa-file-excel fs-3",
                title: _ctx.$t('tabla.serviciosPrestadosListado.boton.imprimir')
              }, null, 8, _hoisted_8)
            ])
          ]),
          _: 1
        }, 8, ["data", "labels", "fields", "name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_el_table, {
        data: _ctx.serviciosListado,
        style: {"width":"100%"},
        stripe: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.responsable'),
            sortable: "",
            prop: "responsable",
            "min-width": "150"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.dni'),
            prop: "familia.dni",
            sortable: "",
            width: "120"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.menores'),
            sortable: "",
            prop: "menoresNombres",
            "min-width": "150"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.direccion'),
            prop: "direccion",
            sortable: "",
            "min-width": "150"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.inicio'),
            prop: "inicioPrevisto",
            formatter: _ctx.toLocaleDateString,
            sortable: "",
            width: "110"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosPrestadosListado.cabecera.finPrevisto'),
            prop: "finPrevisto",
            formatter: _ctx.toLocaleDateString,
            sortable: "",
            width: "130"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.familias.cabecera.estado'),
            sortable: "",
            width: "120",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_tag, {
                class: "ml-2",
                type: 
                scope.row.estado == 'APROBADO'
                  ? 'success'
                  : scope.row.estado == 'RECHAZADO'
                  ? 'danger'
                  : ''
              
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.estado), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            width: "50",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                type: "text",
                onClick: ($event: any) => (_ctx.handleEdit(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosPrestadosListado.fila.ver")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}