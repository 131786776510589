import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabla = _resolveComponent("Tabla")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_Tabla, { servicios: _ctx.servicios }, null, 8, ["servicios"]), [
      [_directive_loading, _ctx.cargaListado]
    ])
  ]))
}