
import { showBackendError } from "@/assets/ts/_utils/ShowBackendError";
import comarcas from "@/core/data/comarcas";
import tratamientos from "@/core/data/tratamientos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { diffObjects } from "@/core/helpers/diffObjects";
import Page from "@/layout/Page.vue";
import { AuthModule, OrganismosModule } from "@/store/modules";
import OrganismoDatosGestion from "@/views/organismos/organismosDetalleTabs/OrganismoDatosGestion.vue";
import OrganismoDatosIndividuales from "@/views/organismos/organismosDetalleTabs/OrganismoDatosIndividuales.vue";
import OrganismoDetalleServicios from "@/views/organismos/organismosDetalleTabs/OrganismoDetalleServicios.vue";
import OrganismoProyectosN2 from "@/views/organismos/organismosDetalleTabs/OrganismoProyectosN2.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface FormState {
  nombre: string;
  tipoOrganizacion: string;
  telefono?: string | null;
  direccion?: string | null;
  esUbicacionGeografica?: boolean | null;
  email?: string | null;
  emailProfesionales?: string | null;
}

export default defineComponent({
  name: "organismo-detalle",
  components: {
    ErrorMessage,
    Page,
    OrganismoDetalleServicios,
    OrganismoDatosIndividuales,
    OrganismoProyectosN2,
    OrganismoDatosGestion,
    ArrowDown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const organismoId = route.params.id as string;
    const store = useStore();

    const module = getModule(OrganismosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const editingInfoOrganismo = ref<boolean>(false);
    const creandoOrganismo = ref<boolean>(false) ? !organismoId : true;

    const schema = Yup.object({
      nombre: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.nombre.label")),
      tipoOrganizacion: Yup.string()
        .required()
        .label(t("formularios.organismoDetalle.tipoOrganismo.label")),
      telefono: Yup.string()
        .nullable()
        .label(t("formularios.organismoDetalle.telefono.label")),
      esUbicacionGeografica: Yup.boolean()
        .nullable()
        .label(t("formularios.organismoDetalle.telefono.label")),
      direccion: Yup.string()
        .nullable()
        .label(t("formularios.organismoDetalle.direccion.label")),
      email: Yup.string()
        .email()
        .nullable()
        .label(t("formularios.organismoDetalle.notificaciones.familias")),
      emailProfesionales: Yup.string()
        .email()
        .nullable()
        .label(t("formularios.organismoDetalle.notificaciones.profesionales")),
    });

    const { resetForm, handleSubmit, meta } = useForm<Partial<FormState>>({
      validationSchema: schema,
    });

    const fetchOrganizacionesList = async function () {
      await module.fetchTipoOrganizaciones();
    };

    const cargaDetalle = ref<boolean>(false);
    const fetchOrganismo = async function () {
      cargaDetalle.value = true;
      await module
        .fetchById(organismoId)
        .catch((err) => {
          if (typeof err === "object" && err?.error?.statusCode < 500) {
            showBackendError(t, err.error);
          } else {
            Swal.fire({
              text: t("formularios.organismo.cargar.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        })
        .finally(() => {
          cargaDetalle.value = false;
        });
    };

    const guardaDetalle = ref<boolean>(false);
    const guardarOrganismo = handleSubmit(async (values) => {
      guardaDetalle.value = true;

      const update = diffObjects(meta.value.initialValues, values);
      if (Object.keys(update).length) {
        await module
          .updateById({
            id: organismoId,
            organismo: {
              ...update,
            },
          })
          .then(() => {
            ElNotification({
              message: t("formularios.organismo.guardar.mensaje.exito"),
              type: "success",
            });
            return router.replace("/listados/organismos");
          })
          .catch((err) => {
            if (typeof err === "object" && err?.error?.statusCode < 500) {
              showBackendError(t, err.error);
            } else {
              ElNotification({
                message: t("formularios.organismo.cargar.error"),
                type: "error",
              });
            }
          })
          .finally(() => {
            guardaDetalle.value = false;
          });
      } else {
        ElNotification({
          message: t("formularios.organismo.guardar.mensaje.exito"),
          type: "success",
        });
        guardaDetalle.value = false;
        return router.replace("/listados/organismos");
      }
    });

    onMounted(() => {
      if (organismoId) fetchOrganismo();
      fetchOrganizacionesList();

      setCurrentPageBreadcrumbs(t("menu.nuevo"), [
        t("menu.usuarios"),
        { title: t("menu.organismosListado"), path: "/listados/organismos" },
      ]);

      if (!organismoId) {
        resetForm({
          values: {
            nombre: "",
            tipoOrganizacion: "",
            telefono: "",
            direccion: "",
            esUbicacionGeografica: false,
            email: "",
            emailProfesionales: "",
          },
        });
      }
    });

    const crearOrganismo = handleSubmit(async (values) => {
      guardaDetalle.value = true;

      try {
        await module.crear({
          nombre: values.nombre,
          tipoOrganizacionId: values.tipoOrganizacion,
          telefono: values.telefono ?? "",
          direccion: values.direccion ?? "",
          esUbicacionGeografica: values.esUbicacionGeografica ?? false,
          email: values.email ?? "",
          emailProfesionales: values.emailProfesionales ?? "",
        });
        await ElNotification({
          message: t("formularios.organismos.crear.mensaje.exito"),
          type: "success",
        });
        router.replace("/listados/organismos");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (typeof err === "object" && err?.error?.statusCode < 500) {
          return showBackendError(t, err.error);
        }

        ElNotification({
          message: t("formularios.organismos.crear.mensaje.error"),
          type: "error",
        });
      }
    });

    const organismo = computed(() => module.organismo);
    const listadoTiposOrganizaciones = computed(
      () => module.tiposOrganizacionListado
    );

    watch(organismo, () => {
      if (!organismo.value) return;

      resetForm({
        values: {
          nombre: organismo.value.nombre,
          tipoOrganizacion: organismo.value.tipoOrganizacion.nombre,
          esUbicacionGeografica: organismo.value.esUbicacionGeografica,
          telefono: organismo.value.telefono,
          direccion: organismo.value.direccion,
          email: organismo.value.email,
          emailProfesionales: organismo.value.emailProfesionales,
        },
      });

      setCurrentPageBreadcrumbs([organismo.value.nombre].join(" "), [
        t("menu.personas"),
        { title: t("menu.organismosListado"), path: "/listados/organismos" },
      ]);
    });

    const { value: nombre } = useField("nombre");
    const { value: tipoOrganizacion } = useField("tipoOrganizacion");
    const { value: telefono } = useField("telefono");
    const { value: direccion } = useField("direccion");
    const { value: email } = useField("email");
    const { value: emailProfesionales } = useField("emailProfesionales");
    const { value: esUbicacionGeografica } = useField("esUbicacionGeografica");

    const eliminar = async () => {
      if (!organismoId) return;

      const { isConfirmed } = await Swal.fire({
        text: t("dialogs.eliminar.confirmacion"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("dialogs.okButton"),
        cancelButtonText: t("dialogs.cancelButton"),
        reverseButtons: true,
      });

      if (!isConfirmed) return;

      try {
        await module.deleteById(organismoId);
        ElMessage({
          message: t("dialogs.eliminar.exito"),
          type: "success",
        });
        router.replace("/listados/organismos");
      } catch (err) {
        Swal.fire({
          text: t("dialogs.eliminar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: { confirmButton: "btn btn-primary" },
        });
      }
    };

    return {
      tratamientos,
      schema,
      cargaDetalle,
      fetchOrganismo,
      fetchOrganizacionesList,
      guardaDetalle,
      guardarOrganismo,
      crearOrganismo,
      eliminar,
      creandoOrganismo,
      nombre,
      telefono,
      esUbicacionGeografica,
      tipoOrganizacion,
      direccion,
      email,
      emailProfesionales,
      isAdmin: !comarcaId,
      comarcas,
      editingInfoOrganismo,
      listadoTiposOrganizaciones,
    };
  },
});
