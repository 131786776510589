
import Tabla from "@/components/servicios/TablaServiciosProgramadosOrganismos.vue";
import { ServiciosProgramadosModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-programados-listado-organismos",
  components: { Tabla },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const organismoId = route.params.id as string;
    const comarcaId = organismoId;
    const module = getModule(ServiciosProgramadosModule, store);

    const error = computed(() => module.errors);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchListado(comarcaId);
      } catch (err) {
        Swal.fire({
          text: t("formularios.serviciosProgramadosListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    return {
      servicios: computed(() => module.servicios),
      cargaListado,
    };
  },
});
