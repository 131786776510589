
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { ServicioProgramado } from "@/store/models";
import moment from "moment";
import { computed, defineComponent, ref, watch } from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "servicios-listado-organismos",
  components: { "download-excel": JsonExcel },
  props: {
    servicios: Object as () => ServicioProgramado[],
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const organismoId = route.params.id as string;
    const comarcaId = organismoId;
    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      nombre: t("csv.listados.serviciosProgramados.nombre"),
      descripcion: t("csv.listados.serviciosProgramados.descripcion"),
      direccion: t("csv.listados.serviciosProgramados.direccion"),
      plazas: t("csv.listados.serviciosProgramados.plazas"),
      inscripcionAbierta: t(
        "csv.listados.serviciosProgramados.inscripcionAbierta"
      ),
      inicioPrevistoDate: t("csv.listados.serviciosProgramados.inicioPrevisto"),
      finPrevistoDate: t("csv.listados.serviciosProgramados.finPrevisto"),
      solicitudes: t("csv.listados.serviciosProgramados.solicitudes"),
      admisiones: t("csv.listados.serviciosProgramados.admisiones"),
    };

    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "nombre",
      "descripcion",
      "direccion",
      "plazas",
      "inscripcionAbierta",
      "inicioPrevistoDate",
      "finPrevistoDate",
      "solicitudes",
      "admisiones",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const handleEdit = (index: number, row: { id: string }) => {
      router.push(`/listados/servicios-programados/${row.id}`);
    };

    const calculateLugar = (record) => {
      const { otrosEspacioColectivo, lugarEspacioColectivo, lugarPrestacion } =
        record.servicio;

      if (lugarPrestacion !== "ESPACIO") {
        return t(`lugarPrestacion.${lugarPrestacion}`);
      }

      if (lugarEspacioColectivo !== "OTROS") {
        return t(`lugarEspacioColectivo.${lugarEspacioColectivo}`);
      }

      return otrosEspacioColectivo ?? "";
    };

    watch(
      () => props.servicios,
      () => {
        currentPage.value = 1;
      }
    );

    const servicios = computed(() => {
      return props.servicios?.map((ele) => {
        return {
          ...ele,
          direccion: ele.servicio.direccion,
          inicioPrevisto: moment(ele.servicio.inicioPrevisto).format(
            "YYYY-MM-DD"
          ),
          finPrevisto: moment(ele.servicio.finPrevisto).format("YYYY-MM-DD"),
          solicitudes: ele.peticionFormularios?.length ?? 0,
          solicitudesPendientes: ele.peticionFormularios?.some(
            (pF) => pF.estado === "PENDIENTE"
          ),
          admisiones: ele.servicio.menoresServicio?.length ?? 0,
          inicioPrevistoDate: new Date(ele.servicio.inicioPrevisto),
          finPrevistoDate: new Date(ele.servicio.finPrevisto),
          nombreComarca: ele.comarca?.nombre,
          lugarCalculado: calculateLugar(ele),
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      (servicios.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter(
          (data) =>
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.lugarCalculado, search.value) ||
            textSearch(data.nombre, search.value) ||
            textSearch(data.descripcion, search.value)
        )
    );

    const serviciosListado = computed(() => {
      const filteredBySearch = (servicios.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter(
          (data) =>
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.lugarCalculado, search.value) ||
            textSearch(data.nombre, search.value) ||
            textSearch(data.descripcion, search.value)
        );

      return filteredBySearch;
    });

    const { result, currentPage, lastPage } = useArrayPagination(
      serviciosListado,
      { pageSize: 100 }
    );

    const unsetPage = () => {
      if (currentPage) {
        currentPage.value = 1;
      }
    };

    watch(result, () => {
      unsetPage();
    });

    return {
      serviciosListado,
      filteredCollection,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      search,
      toLocaleDateString,
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
    };
  },
});
