
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import lugaresPrestacion from "@/core/data/lugaresPrestacion";
import { ServicioListado } from "@/store/modules";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel },
  props: {
    servicios: Object as () => ServicioListado[] | null,
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const organismoId = route.params.id as string;
    const comarcaId = organismoId;

    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      familiaNombre: t("csv.listados.servicios.nombre"),
      familiaApellidos: t("csv.listados.servicios.apellidos"),
      familiaDNI: t("csv.listados.servicios.dni"),
      direccion: t("csv.listados.servicio.direccion"),
      familiaIngresos: t("csv.listados.servicios.ingresos"),
      familiaPrioritaria: t("csv.listados.servicios.familiaPrioritaria"),
      menoresNombres: t("csv.listados.servicios.menores"),
      inicioPrevistoDate: t("csv.listados.servicios.inicioPrevisto"),
      finPrevistoDate: t("csv.listados.servicios.finPrevisto"),
      descripcion: t("csv.listados.servicios.descripcion"),
      lugarPrestacionCSV: t("csv.listados.servicios.lugarPrestacion"),
      lugarEspacioColectivoCSV: t(
        "csv.listados.servicios.lugarEspacioColectivo"
      ),
      otrosEspacioColectivoCSV: t(
        "csv.listados.servicios.otrosEspacioColectivo"
      ),
      estado: t("csv.listados.servicios.estado"),
    };

    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "familiaNombre",
      "familiaApellidos",
      "familiaDNI",
      "direccion",
      "familiaIngresos",
      "familiaPrioritaria",
      "menoresNombres",
      "inicioPrevistoDate",
      "finPrevistoDate",
      "descripcion",
      "lugarPrestacionCSV",
      "lugarEspacioColectivoCSV",
      "otrosEspacioColectivoCSV",
      "estado",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const handleEdit = (index: number, row: { id: string }) => {
      router.push(`/listados/servicios/${row.id}`);
    };

    const registros = computed(() => {
      return props.servicios?.map((row) => {
        return {
          ...row,
          familiaNombre: row.familia?.nombre,
          familiaApellidos: row.familia?.apellidos,
          responsable: [row.familia?.nombre, row.familia?.apellidos].join(" "),
          familiaDNI: row.familia?.dni,
          familiaIngresos: row.familia?.ingresos,
          familiaPrioritaria: row.familia?.familiaPrioritaria,
          menoresNombres: row.menores
            ?.map((menor) => menor.nombre + " " + menor.apellidos)
            .join(", "),
          inicioPrevistoDate: new Date(row.inicioPrevisto),
          finPrevistoDate: new Date(row.finPrevisto),
          nombreComarca: row.comarca?.nombre,
          lugarPrestacionCSV: lugaresPrestacion.find(
            (e) => e.id === row.lugarPrestacion
          )?.nombre,
          lugarEspacioColectivoCSV: lugaresEspaciosColectivos.find(
            (e) => e.id === row.lugarEspacioColectivo
          )?.nombre,
          otrosEspacioColectivoCSV: row.otrosEspacioColectivo,
        };
      });
    });

    const serviciosListado = computed(() => {
      const filteredBySearch = registros.value?.filter(
        (data) =>
          !search.value ||
          textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
          textSearch(data.responsable, search.value) ||
          textSearch(data.familia?.dni, search.value) ||
          textSearch(data.menoresNombres, search.value) ||
          textSearch(data.direccion, search.value) ||
          textSearch(data.estado, search.value)
      );

      return filteredBySearch;
    });

    return {
      serviciosListado,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      search,
      toLocaleDateString,
    };
  },
});
